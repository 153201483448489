// 当前这个模块：API进行统一管理
import requests from "./request";

// 信息列表接口
export const reqInfoData = (infoClass, params) => requests({
    url: `/${infoClass}/`,
    method: 'get',
    params: params
})

// 信息内容接口
export const reqInfoContent = (infoClass, id) => requests({
    url: `/${infoClass}/${id}/`,
    method: 'get'
})

// 信息提交接口
export const reqPostInfo = (infoClass,data) => requests({
    data: data,
    url: `/${infoClass}/`,
    method: 'post'
})

// 天气API接口
export const reqWeather = () => requests({
    // 免费接口
    // url: 'https://api.seniverse.com/v3/weather/now.json?key=SktVRlTF5SJMTagJZ&location=lanzhou&language=zh-Hans&unit=c',
    url: 'https://api.seniverse.com/v3/weather/now.json?key=SktVRlTF5SJMTagJZ&location=gannan&language=zh-Hans&unit=c',
    // 付费接口
    // url: 'https://api.seniverse.com/v3/weather/now.json?key=SMPEU_uBCHjtZbGwv&location=lintan&language=zh-Hans&unit=c',
    method: 'get',
})