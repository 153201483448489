// 以下写法为VUE3写法
import { createStore } from 'vuex'
// 引入小仓库
import info from './info';
import api from './api';

export default createStore({
    // 实现vuex仓库模块式开发存储数据
    modules: {
        info,  // 注册小仓库
        api,
    }
})

