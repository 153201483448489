<template>
  <div class="foot">
    <!-- 二维码图标 -->
    <div class="min-ico">
      <div class="content">
        <div class="icoModal">
          <div class="ico"><img src="./images/f_1.png" alt="" /></div>
          <div class="sanjiaoModal">
              <div class="box1"><img src="../../assets/QRCode/qrcode-01.png"></div>
              <div class="box2"></div>
          </div>
        </div>
        <div class="icoModal">
          <div class="ico"><img src="./images/f_2.png" alt="" /></div>
          <div class="sanjiaoModal">
              <div class="box1"><img src="../../assets/QRCode/qrcode-03.png"></div>
              <div class="box2"></div>
          </div>
        </div>
        <div class="icoModal">
          <div class="ico"><img src="./images/f_3.png" alt="" /></div>
          <div class="sanjiaoModal">
              <div class="box1"><img src="../../assets/QRCode/qrcode-02.png"></div>
              <div class="box2"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="foot-container clearfix">
      <!-- LOGO -->
      <div class="foot-content-img">
        <!-- <p>我们在这些地方</p> -->
        <div class="foot-logo clearfix">
          <div>
            <img src="./images/logo.png" alt="" />
            <p>山水冶力关·生态大观园</p>
          </div>
          <!-- <div>
              <img src="./images/qrcode-02.png" alt="">
              <p>官方APP</p>
          </div> -->
        </div>
      </div>
      <div class="foot-content-text">
        <ul>
          <li><router-link to="/home/" style="font-weight:600">首页</router-link></li>
          <li><router-link to="/SpecialPage/aloneinfo/1/">冶力关概况</router-link></li>
          <li><router-link to="/scenicspot/">旅游区景点</router-link></li>
          <li><router-link to="/activitylist/">旅游活动</router-link></li>
          <li><router-link to="/newslist/">最新动态</router-link></li>
          <li><router-link to="/noticelist/">旅游区公告</router-link></li>
          <li><router-link to="/Problem/">常见问题</router-link></li>
        </ul>
      </div>
      <div class="foot-content-text">
        <ul>
          <li style="font-weight:600">联系我们</li>
          <li>咨询：0941—3272666</li>
          <li>投诉：0941—3271366</li>
          <li>救援：0941—3271355</li>
          <li>地址：甘肃省甘南藏族自治州临潭县冶力关镇滨河北路</li>
        </ul>
      </div>
      <div class="foot-content-text">
        <ul>
          <li style="font-weight:600">友情链接</li>
          <li><a href="http://wlt.gansu.gov.cn/" target="blank">甘肃省文旅厅</a></li>
          <li><a href="http://www.gnzrmzf.gov.cn/" target="blank">甘南州人民政府</a></li>
          <li><a href="http://www.lintan.gov.cn/" target="blank">临潭县人民政府</a></li>
        </ul>
      </div>
    </div>
    <div style="height: 20px"></div>
    <div class="foot-info clearfix clearfix">
      <div class="foot-info-left">
        <p>Copyrights © 2023 All rights reserved.版权所有：临潭县冶力关旅游区管理中心&nbsp;&nbsp;&nbsp;&nbsp;邮政编码：747506</p>
       <p><a href="https://beian.miit.gov.cn/#/Integrated/index" target="blank">陇ICP备2023000885号</a>&nbsp;&nbsp;&nbsp;<img src="./images/bhIcon.png">公安备62302102000119号</p>
      </div>
      <p>技术支持：甘肃云山文化传媒有限公司</p>
      <div style="height: 100px"></div>
    </div>
  </div>
</template>

<script>
export default {

};
</script>

<style lang="scss">
/* 页脚 */
.foot {
  width: 100%;
  height: auto;
  background-color: #f3f3f3;
  color: #666 !important;
  font-size: 14px;
}

.min-ico {
  width: 100%;
  height: 80px;
  background-color: #e9e9e9;
  .content {
    max-width: 1260px;
    margin: 0 auto;
    padding: 23px 0 0 40px;
  }
}

// 三角弹出框
.icoModal {
  position: relative;
  width: 55px;
  float: left;
  .ico {
    float: left;
    margin-right: 20px;
  }
  .ico:hover + .sanjiaoModal {
    display: block;
  }
  .sanjiaoModal {
    /* 我们再给父元素设置一个相对定位*/
    position: absolute;
    top: -125px;
    left: -36px;
    display: none;
    .box1 {
      width: 110px;
      height: 110px;
      background-color: #fff;
      border-radius: 5px;
      box-shadow: 0 0 8px rgb(0 0 0 / 5%);
      padding: 5px;
      img {
        width: 100%;
      }
    }
    .box2 {
      width: 0px;
      height: 0px;
      /* 设置四边边框宽度，将颜色设置为透明 */
      border: 10px solid transparent;
      /* 再进行下面的设置，这样之后就会出现一个向上的一个小三角*/
      border-top: 10px solid #fff;
      position: relative;
      left: 45px;
      top: 0px;
    }
  } 
}

.foot-container {
  max-width: 1260px;
  height: auto;
  margin: 0 auto;
  padding: 50px 0px 50px 0px;
  border-bottom: 1px solid #dbdbdb;
}

.foot-content-img {
  width: 25%;
  float: left;
}

.foot-content-img > p {
  font-weight: 400;
  letter-spacing: 2px;
  margin-bottom: 20px;
}

.foot-content-img .foot-logo div p {
  color: #666 !important;
}

/* 二维码 */
.foot-logo {
  width: 100%;
  display: flex;
}

.foot-logo :nth-of-type(2) {
  padding-left: 20px;
}

.foot-logo div {
  width: 200px;
  /* height: 169px; */
}

.foot-logo div img {
  width: 100%;
}

.foot-logo div p {
  color: #666;
  line-height: 29px;
  text-align: center;
  /* font-weight: 600; */
}

/* 三列文字 */

.foot-content-text {
  width: 16.6%;
  margin-left: 8%;
  float: left;
}

.foot-content-text ul li {
  margin-bottom: 13px;
}

.foot-content-text ul li a {
  color: #666 !important;
}

/* 最底部信息 */

.foot-info {
  max-width: 1260px;
  margin: 5px auto 0 auto;
  line-height: 30px;
}

.foot-info-left {
  float: left;
}

.foot-info > p {
  float: right;
}

@media (max-width: 560px) {
  .foot {
    font-size: 12px;
  }
  .min-ico .content {
    margin-left: 20px;
  }
  .foot-container {
    padding: 30px 6px 20px 6px;
  }
  .foot-info {
    padding: 0px 10px 30px 10px;
  }
  .foot-info > p {
    float: left;
  }
}
</style>