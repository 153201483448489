import { reqWeather } from "@/api";


const state = {
    WeatherData: {},
};

const mutations = {

    WeatherData(state, WeatherData) {
        state.WeatherData = WeatherData
    },
};

const actions = {

    // 获取内容详情
    async WeatherData({ commit }) {
        let result = await reqWeather()
        if (result !== {}) {
            console.log('仓库 获取天气数据 请求成功');
            commit('WeatherData', result.results[0])
        }
    }
};

const getters = {};

// 对外暴露
export default {
    state,
    mutations,
    actions,
    getters
}