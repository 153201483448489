import { createApp } from 'vue'
import App from './App.vue'
// 引入ElementPlus
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

// 引入bootstrap
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
/* 引入图标字体样式 */
import './assets/css/icomoon.css'
/* 引入公共样式 */
import './assets/css/reset.css'
// 引入路由组件
import router from '@/router/index'
// 仓库 引入仓库
import store from '@/store'
// 分页器注册为全局组件
import Pagination from '@/components/Pagination'
// Vue.component(Pagination.name, Pagination)
// 引入Animate.css动画库（还需要在public/index.html中配置）

// 动画
import 'animate.css';


const app = createApp(App)
// 挂载路由
app.use(router)
// 注册仓库
app.use(store)
// 注册ElementPlus
app.use(ElementPlus)
// 分页器 component 第一个参数组件名称 第二个参数组件实例
app.component('Pagination',Pagination)
// 全局参数配置(Vue 3.x)
// app.config.globalProperties.$DETAIL_IMG = "http://127.0.0.1:8000/static/upload/"
app.config.globalProperties.$DETAIL_IMG = "https://api.ylgly.com/static/upload/"

app.mount('#app')


// 修改页面标题
router.beforeEach((to, from, next) => {
    /* 路由发生变化修改页面title */
    if (to.meta.title) {
        document.title = to.meta.title
    }
    next()
})