<template>
  <div class="home-page">
    <!-- 组件 第3步 使用组件 -->
    <Header></Header>
    <!-- 路由组件出口的地方 -->
    <router-view></router-view>
    <Footer></Footer>
  </div>
</template>

<script>
// 组件 第1步 引入组件
import Header from './components/Header/'
import Footer from './components/Footer'

export default {
  name: 'App',

  components: {
    // 组件 第2步 注册组件
    Header,
    Footer,
    }

}
</script>

<style lang="scss">

/* 整体页面 */
.home-page {
  width: 100%;
  max-width: 2560px;
  margin: 0 auto;
  line-height: 1.6;
}

/* 公共样式 */

a:hover {
  color: #74ab71 !important;
}

.title-h3 {
  color: #74ab71;
  font-size: 32px;
  font-weight: 400;
  /* padding-top: 40px; */
}

.title-h3 i {
  width: 4px;
  height: 30px;
  background-color: #74ab71;
  margin-top: 12px;
  margin-right: 15px;
  float: left;
}

/* 动画 */
@keyframes turnY {
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(179deg);
  }
  100% {
    transform: rotateY(360deg);
  }
}

</style>
