import { reqInfoData, reqInfoContent } from "@/api";


// 仓库存储数据的地方
const state = {
    // state默认数据要与返回数据对应
    IndexNoticeData:{},
    Notice:{},
    News:{},
    news5A:{},
    ActivityData:{},
    InfoContent:{},
    ScenicSpot:{},
    Video:{},
    Picture:{},
    VR:{}
};

// 修改state数据的唯一手段
const mutations = {

    IndexNoticeData(state, IndexNoticeData) {
        state.IndexNoticeData = IndexNoticeData
    },
    Notice(state, Notice) {
        state.Notice = Notice
    },
    News(state, News) {
        state.News = News
    },
    news5A(state, news5A) {
        state.news5A = news5A
    },
    ActivityData(state, ActivityData) {
        state.ActivityData = ActivityData
    },
    InfoContent(state, InfoContent) {
        state.InfoContent = InfoContent
    },
    ScenicSpot(state, ScenicSpot) {
        state.ScenicSpot = ScenicSpot
    },
    Video(state, Video) {
        state.Video = Video
    },
    Picture(state, Picture) {
        state.Picture = Picture
    },
    VR(state, VR) {
        state.VR = VR
    }
};

// 处理action，可以书写自己的业务逻辑，也可以处理异步
const actions = {

    // 获取内容数据
    async InfoData({commit}, {infoClass,params}) {
        // 向服务器发送请求
        let result = await reqInfoData(infoClass, params)
        if (result.count >= 0) {
            if (infoClass == 'index-notice') {
                console.log('获取首页公告数据 请求成功');
                // 通知mutations修改数据
                commit('IndexNoticeData', result)
            }
            if (infoClass == 'news') {
                console.log('获取内容数据 请求成功');
                // 通知mutations修改数据
                commit('News', result)
            }
            if (infoClass == '5A') {
                console.log('获取5A内容数据 请求成功');
                // 通知mutations修改数据
                commit('news5A', result)
            }
            if (infoClass == 'activity') {
                console.log('获取活动数据 请求成功');
                // 通知mutations修改数据
                commit('ActivityData', result)
            }
            if (infoClass == 'scenicspot') {
                console.log('获取景区数据 请求成功');
                // 通知mutations修改数据
                commit('ScenicSpot', result)
            }
            if (infoClass == 'video') {
                console.log('获取视频数据 请求成功');
                // 通知mutations修改数据
                commit('Video', result)
            }
            if (infoClass == 'picture') {
                console.log('获取图游数据 请求成功');
                // 通知mutations修改数据
                commit('Picture', result)
            }
            if (infoClass == 'notice') {
                console.log('获取公告数据 请求成功');
                // 通知mutations修改数据
                commit('Notice', result)
            }
            if (infoClass == 'vr') {
                console.log('获取vr数据 请求成功');
                // 通知mutations修改数据
                commit('VR', result)
            }
        }
    },

    // 获取内容详情
    async InfoContent({ commit }, { infoClass, id }) {
        let result = await reqInfoContent(infoClass, id)
        if (result.id) {
            console.log('获取内容详细信息 请求成功');
            commit('InfoContent', result)
        }
    }
};

// 理解为计算属性，用于简化仓库数据，让组件获取仓库的数据更方便
const getters = {};

// 对外暴露
export default {
    state,
    mutations,
    actions,
    getters
}